import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

import ptOrange from '../images/pt_orange.gif'
import ptYellow from '../images/pt_yellow.gif'
import mp3Icon from '../images/icon_mp3.gif'
import projectsIcon from '../images/icon_projects.gif'

const HomePage = () =>
  <Layout>
    <Helmet title="Hexy Music &gt; Welcome" />

    <main id="content">
      <h2>&raquo; Welcome</h2>

      <p>Welcome to <img src={ ptYellow } alt="" /> HexyMusic <img src={ ptOrange } alt="" />.
      Here you will find sample tracks of several old music projects dating back
      to high school as well as a few new ones as time progresses.</p>

      <h3><Link to="/download/"><img src={mp3Icon} alt="" className="icon" /> listen / download  &laquo;</Link></h3>
      <p>Go straight to the downloads section to hear the samples at HexyMusic in action.</p>

      <h3><Link to="/projects/"><img src={projectsIcon} alt="" className="icon" /> projects &laquo;</Link></h3>
      <p>Check out the projects section for the latest information on up and coming
      songs and musical experiments. Although, this may not be updated very often.</p>

      <h3><a href="https://www.singular.co.nz/" rel="external"><img src={ ptOrange } alt="" className="" /> singular website developments &laquo;</a></h3>
      <p>These days my time is spent building my business, Singular Limited. Over there,
      we specialise in custom ASP.NET and JavaScript development for web based applications,
      B2B and ecommerce systems.</p>
    </main>

    <aside id="ear">
      &raquo;
    </aside>
  </Layout>

export default HomePage
